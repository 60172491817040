import React, {createContext, useState} from 'react'


export const AppContext = createContext()

export const AppProvider = ({children}) => {
    
    const [proyect, setProyect] = useState({
        title: 'Amoquinety',
        autor: 'Amoquinety',
        autores: ['Angel Giolitti'],
        type: 'Album',
        date: '1998',
        img: '/img/foto-portada-1.jpeg',
        id: 0,
        tracks : [
            {
                name: "Arround the round",
                autor: 'Amokinety',
                src: "/songs/amoquinety/01-arround-the-round.wav"
            },
            {
                name: "A preta samba",
                autor: 'Amokinety',
                src: "/songs/amoquinety/02-a-preta-samba.wav"
            },
            {
                name: "Ludmila",
                autor: 'Amokinety',
                src: "/songs/amoquinety/03-ludmila.wav"
            },
            {
                name: "Airland",
                autor: 'Amokinety',
                src: "/songs/amoquinety/04-airland.wav"
            },
            {
                name: "Amokyneti",
                autor: 'Amokinety',
                src: "/songs/amoquinety/05-amokyneti.wav"
            },
            {
                name: "Belllisima",
                autor: 'Amokinety',
                src: "/songs/amoquinety/06-bellisima.wav"
            },
            {
                name: "Siempre así",
                autor: 'Amokinety',
                src: "/songs/amoquinety/07-siempre-asi.wav"
            },
            {
                name: "Abasto",
                autor: 'Amokinety',
                src: "/songs/amoquinety/08-abasto.wav"
            }
        ]
    })
    const [proyectPlay, setProyectPlay] = useState(false)
    const [tracks, setTracks] = useState(proyect.tracks)
    const [trackIndex, setTrackIndex] = useState(tracks.length-2)

    return(
        <AppContext.Provider value={[proyect,setProyect, proyectPlay, setProyectPlay, 
        tracks, setTracks, trackIndex, setTrackIndex]}>
            {children}
        </AppContext.Provider>
    )
}