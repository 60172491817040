import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setProject, setOnPlay, setTrackIndex, setViewTrackList, setTrack } from '../app/playerSlice'
import './proyectView.css';
import { GalleryProvider } from '../components/galleryContext/GalleryContext'
import ProjectGallery from '../components/projectGallery.js/ProjectGallery'
import TrackList from '../components/trackList/TrackList'
import AlbumItem from '../components/albumsItem/AlbumsItem'

export default function ProyectView() {
    const [navState, setNavState] = useState()
    const dispatch = useDispatch()

    const playerState = useSelector((state) => state.player)
    let project = playerState.projects.find(item => item._idx === playerState.viewSelected)

    const SocialMedia = ({ social }) => {
        const { instagram, facebook, youtube, twitter, spotify } = social
        return (
            <div>
                {
                    instagram || facebook || twitter || youtube || spotify ?
                        <div className='contact-social'>
                            {instagram.length > 0 && (
                                <a href={instagram} title='Instagram' target='_blank'>
                                    <img src='/img/icons/instagram.png' />
                                </a>
                            )}
                            {facebook.length > 0 && (
                                <a href={facebook} title='Facebook' target='_blank'>
                                    <img src='/img/icons/fb-white.png' />
                                </a>
                            )}
                            {youtube.length > 0 && (
                                <a href={youtube} title='Youtube' target='_blank'>
                                    <img src='/img/icons/yb.png' />
                                </a>
                            )}
                            {spotify.length > 0 && (
                                <a href={spotify} title='Spotify' target='_blank'>
                                    <img src='/img/icons/spotify-png.png' />
                                </a>
                            )}
                        </div>
                    : ''
                }
            </div>
        )
    }

    function AlbumsContainer() {
        return (
            <div className='albums-container'>
                {project.albums.map(item => <AlbumItem item={item} />)}
            </div>
        )
    }

    // Función para renderizar la lista de archivos PDF
    function PartsList() {
        return (
            <div className='parts-list'>
                {project.media.pdfs && project.media.pdfs.map((part, index) => (
                    <div key={index} className='pdf-link'>
                        <a href={part.url} target='_blank' rel='noopener noreferrer'>
                            {part.title}{' - PDF'}
                        </a>
                    </div>
                ))}
            </div>
        )
    }

    useEffect(() => {
        if (!navState) {
            window.scroll(0, 0)
        }
        if (navState !== 'albums' && (project.media.pictures[0] || project.media.videos[0])) {
            setNavState('galeria')
        }
        if (navState !== 'galeria' && project.albums[0] && playerState.viewTrackList === false) {
            setNavState('albums')
            window.scroll(0, 0)
        }
        if (playerState.viewTrackList || (playerState.onPlay === true && playerState.viewTrackList !== false)) {
            setNavState('tracks')
        }
    }, [playerState])

    if (project) {
        return (
            <div className='proyect-view'>
                <div className='proyect-header'>
                    <div className='header' style={{ backgroundImage: `url('${project.img.portada}')` }}>
                        <div className='img-content col-md-5' style={{ backgroundImage: `url('${project.img.perfil}')` }}></div>
                        <div className='proyect-info col'>
                            <h4>{project.type.charAt(0).toUpperCase()+project.type.slice(1)}</h4>
                            <h2>{project.name}</h2>
                            <h3>{project.year} { project.endYear? `- ${project.endYear}` : ''}</h3>
                        </div>
                    </div>
                    <div className='body'>
                        {project.members && project.members.length > 0 && <h2>Integrantes</h2>}
                        {project.members && project.members.length > 0 && (
                            <div className='proyect-members'>
                                {project.members.map(item => (
                                    <div key={item.name}>
                                        <h2>{item.name}</h2>
                                        <div className='roll'>
                                            {item.roll.map((role, idx) => (
                                                <h3 key={idx}>{idx > 0 ? `, ${role}` : role}</h3>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <SocialMedia social={project.socialMedia} />
                        {project.bio.length !== 0 && (
                            <div className='proyect-bio'>
                                <h3>{project.bio}</h3>
                            </div>
                        )}
                    </div>
                </div>
                <div className='proyect-nav'>
                    <ul>
                        {project.albums[0] && (
                            <li>
                                <button className={navState === 'albums' ? 'active' : ''} onClick={() => { setNavState('albums'); dispatch(setViewTrackList({ value: false })) }}>Albums</button>
                            </li>
                        )}
                        {(project.media.pictures[0] || project.media.videos[0]) && (
                            <li>
                                <button className={navState === 'galeria' ? 'active' : ''} onClick={() => { setNavState('galeria'); dispatch(setViewTrackList({ value: false })) }}>Galeria</button>
                            </li>
                        )}
                        {project.media.pdfs && project.media.pdfs.length > 0 && (
                            <li>
                                <button className={navState === 'partes' ? 'active' : ''} onClick={() => setNavState('partes')}>Partituras</button>
                            </li>
                        )}
                    </ul>
                </div>
                <div className='proyect-body'>
                    {navState === 'albums' ? <AlbumsContainer /> :
                    navState === 'galeria' ? <GalleryProvider><ProjectGallery navState={navState} /></GalleryProvider> :
                    navState === 'tracks' && project.albums[playerState.albumSelected].tracks ? <TrackList tracks={project.albums[playerState.albumSelected].tracks} /> :
                    navState === 'partes' ? <PartsList /> : ''}
                </div>
                <div style={{ width: '100%', minHeight: '7rem', marginTop: '1rem' }}></div>
            </div>
        )
    } else {
        return (
            <>Cargando...</>
        )
    }
}
