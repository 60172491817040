import React, {createContext, useState} from 'react'

const urlGallery = [ {url:'/img/foto-portada-1.jpeg', id:1}, {url:'/img/foto-portada-2.jpeg', id:2}, {url:'/img/foto-portada-3.jpeg', id:3}
, {url:'/img/foto-portada-4.jpeg', id:4}, {url:'/img/01.jpeg', id:5} ]

export const GalleryContext = createContext()

export const GalleryProvider = ({children}) => {
    
    const [galleryList, setGalleryList] = useState(urlGallery)
    const [imgSelected, setImgSelected] = useState()
    const [viewer, setViewer] = useState(false)
    const [galleryView, setGalleryView] = useState('fotos')
    const [navState, setNavState] = useState('proyectos')

    return(
        <GalleryContext.Provider value={[navState, setNavState, viewer, setViewer, galleryView, setGalleryView, galleryList, setGalleryList, imgSelected, setImgSelected]}>
            {children}
        </GalleryContext.Provider>
    )
}