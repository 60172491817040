import { createSlice } from '@reduxjs/toolkit'

const initialProject = {
  title: '',
  autor: '',
  autores: [],
  type: '',
  date: '',
  img: '',
  id: 0,
  trackList : [
      {
          name: "Amokyneti",
          autor: 'Tharahumaras',
          src: "/songs/tarahumaras/01-amokyneti.wav"
      }
  ]
}

export const playerSlice = createSlice({
  name: 'player',
  initialState: {
    projectIndex: 0,
    albumIndex: 0,
    trackIndex:0,
    projects: [],
    project:initialProject,
    track:'',

    onPlay:false,
    onPause:true,
    viewSelected: 0,
    projectSelected: {},
    albumSelected: 0,
    viewTrackList: false,
    initialState: false,
    changeSong: false
  },
  reducers: {
   
    setAlbumSelected: (state,action) => {
      const { value } = action.payload
      state.albumSelected = value
    },
    setViewTrackList: (state,action) => {
      const {value} = action.payload
      state.viewTrackList = value
    },
    setTrack: (state, action) => {
        
      const {track} = action.payload
      console.log(track)
    },
    setProject: (state,action) => {
      const {projectIndex, albumIndex, trackIndex } = action.payload
      state.projectIndex = projectIndex
      state.albumIndex = albumIndex
      state.trackIndex = trackIndex

      state.project = state.projects.find((item)=>{
        return item._idx===projectIndex
      })
    },
    setChangeSong: (state,action) => {
      const {value} = action.payload
      state.changeSong = value
    },
    setInitialState: (state, action) => {
      const {value} = action.payload
      state.initialState = value
    },
    setView: (state, action) =>{

      const {value} = action.payload
      state.viewSelected=value
      console.log(value)
      
    },
    incrementTrackIndex: (state) => {
        state.trackIndex += 1
    },
    decrementTrackIndex: (state) => {
      state.trackIndex-=1
    },
    resetTrackIndex: (state) => {
      state.trackIndex=0;
    },
    setTrackIndex: (state, action) => {
      const {value} = action.payload
      state.trackIndex= value
    },
    setOnPause: (state, action) => {
      const {value} = action.payload
      if(value===true){
        state.onPlay=false
      }
      state.onPause=value
      
    },
    setOnPlay: (state, action) => {
      const {value} = action.payload
      if(value===true){
        state.onPause=false
      }
      if(value===false){
        state.onPause=true
      }
      state.onPlay=value
    },
    setProjects: (state,action) => {
      const {array} = action.payload
     
      state.projects = array
      state.project = state.projects[state.projectIndex]
  
    },
  }
})

// Action creators are generated for each case reducer function
export const {resetTrackIndex,setAlbumSelected, setViewTrackList ,setTrack, setPlayerState, setChangeSong, setOnPause, setOnPlay, setInitialState, setView, setTrackIndex, incrementTrackIndex, decrementTrackIndex, setProject, setProjects, } = playerSlice.actions

export default playerSlice.reducer