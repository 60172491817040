import React, {useState, useContext, useEffect} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Article from '../components/article/Article'

import './home.css'


import CarouselHome from '../components/carouselHome/CarouselHome'
import AudioReproducer from '../components/audioReproducer/AudioReproducer'
import { GalleryProvider } from '../components/galleryContext/GalleryContext'
import ProyectView from './ProyectView'


export default function HomeView() {


    

    const HomeView = () => {
        return(
            <>
                <div className='home-header'>
                <div className='header-shadow-1'>
                </div>
                <div className='header-shadow-2'>
                </div>
                <CarouselHome/>
                <div>
                    <h1>ANGEL</h1>
                    <h1>GIOLITTI</h1>
                    <h2>Músico | Productor</h2>
                </div>

            </div>
            <GalleryProvider>
                <Article/>
            </GalleryProvider>
            
            <div style={{'width': '100%', 'min-height': '7rem', 'margin-top': '1rem' }}></div>
            </>
        )
    }
    
    return(
        
        <div className='home-view'>
            <Router>
                <Routes>

                        <Route exact path='/' element={<HomeView/>} />
                        <Route path='/proyectos/:id' element={<ProyectView/>} />
                </Routes>
            </Router> 
            <AudioReproducer/>
        </div>
    )
    
}