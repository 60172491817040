import { useState, useRef, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {setTrack, setChangeSong, setTrackIndex, resetTrackIndex, incrementTrackIndex, decrementTrackIndex, setProject, setOnPlay, setOnPause } from "../../app/playerSlice";
import store from "../../app/store";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import './style.css'
import axios from 'axios'
import {AppContext} from '../../AppContext'

export default function AudioReproducer() {
    const [project, setProject] = useState()
    const [track, setTrack] = useState()
    
    const dispatch = useDispatch()
    
    let playerState = useSelector((state)=>{
        return state.player
    })
    
    const player = useRef();
    
    
    const audioFunctionPlay = async() => {
            
        const play =  player.current.audio.current.play();

        
        
    };
    const audioFunctionPause= async() => {
            
        const pause =  player.current.audio.current.pause();

        
        
    };


    const handleClickPrevious = () => {
        dispatch(setChangeSong({value:true}))
        dispatch(setOnPlay({value:true}))

        const trackIndex = playerState.trackIndex 
        const newIndex = trackIndex-1
    
        if(trackIndex>0){
            dispatch(decrementTrackIndex())
        }
         else {
            const index = playerState.project.albums[playerState.albumIndex].tracks.length-1
            console.log(index)
            dispatch(setTrackIndex({value:index}))
        }

   
        
    };
    
    const handleClickNext = () => {
        dispatch(setChangeSong({value:true}))
        dispatch(setOnPlay({value:true}))

        const trackIndex = playerState.trackIndex 

        if(trackIndex<playerState.project.albums[playerState.albumIndex].tracks.length-1){
            dispatch(incrementTrackIndex())
        }
         else {
            
            dispatch(resetTrackIndex())
        }
    };
    
    
   
    const PlayerHeader = ({name, autor}) => {
        return(
            <div className='header'>
                <h2>{name}</h2>
                <h3>{autor}</h3>
            </div>
        )
    }


    useEffect( () => {
        const project = playerState.projects.find((item) => {
            return item._idx === playerState.viewSelected
        })
        
      
        if(playerState.onPause === true && playerState.onPlay === false  ){
            audioFunctionPause()
     
        }
      
        if( playerState.onPlay === true && playerState.onPause === false){
            audioFunctionPlay()

            player.current.audio.current.play()
      
        }
        if(!playerState.project.albums || playerState.project.albums.length===0){
            return
        } else {
            
            setTrack(playerState.project.albums[playerState.albumIndex].tracks[playerState.trackIndex])
        }
        //const track = res.data[0].trackList[playerState.trackIndex].src
        //setTrack(track)
     
        
    }, [playerState])

 

  return (
    <div className="audio-player">
      
      <AudioPlayer
      
        className='player'
        // style={{ width: "300px" }}

        // layout="horizontal"
        ref={player}
        src={track ? track.src : ''}
        autoPlay={true}
        showSkipControls={true}
        showJumpControls={false}
        header={<PlayerHeader name={track ? track.title : ''} autor={playerState.project.name}/>}
        autoPlayAfterSrcChange = {false}
        
        onPlay={()=>{
            if(playerState.onPlay === false) {
                dispatch(setOnPlay({value:true}))
            }
        }}
        onPause={()=>{
            if(playerState.onPause === false) {
                dispatch(setOnPause({value:true}))
            }
        }}
        
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        onEnded={handleClickNext}
      
        // other props here
        />
    </div>
  );
}
