import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { setViewTrackList, setOnPlay, setProject, setAlbumSelected } from '../../app/playerSlice'

export default function AlbumItem({ item }) {
    const playerState = useSelector((state) => state.player)
    const dispatch = useDispatch()

    const { _idx, name, img } = item

    return (
        <div
            onClick={() => {
                dispatch(setViewTrackList({ value: true }))
                dispatch(setAlbumSelected({ value: _idx }))
            }}
            className='proyect-link'
        >
            <div className='proyect-card animated-gradient'>
                <div className='card-img-container' style={{ backgroundImage: `url("${img}")` }} />
                <div className='card-content album-card-content'>
                    <div className='card-info'>
                        <h2>{name}</h2>
                    </div>
                    <FontAwesomeIcon
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(setViewTrackList({ value: true }))
                            dispatch(setOnPlay({ value: true }))
                            dispatch(
                                setProject({
                                    projectIndex: playerState.viewSelected,
                                    albumIndex: _idx,
                                    trackIndex: 0
                                })
                            )
                        }}
                        className='card-icon'
                        icon={faPlayCircle}
                    />
                </div>
            </div>
        </div>
    )
}
