import React, {useState, useContext} from 'react' 

import './galery.css'
import Gallery from '../../gallery/Gallery'
import Viewer from '../../viewer/Viewer'

import { GalleryContext } from '../../galleryContext/GalleryContext'


export default function GalleryView() {

    const [navState, setNavState, viewer, setViewer, galleryView, setGalleryView] = useContext(GalleryContext)


    return(
        
            <div className='galery-view'>
                <div className='galery-nav'>
                    <ul>
                        <li>
                            <button onClick={()=>{ setGalleryView('fotos') }} className={galleryView === 'fotos' ? 'active' : ''}>
                                Fotos
                            </button>
                        </li>
        
                    </ul>
                </div>
                {
                    galleryView === 'fotos' ? <Gallery/> : '' 
                }
                {
                    viewer === true ? <Viewer /> : ''
                }
            </div>
    )
} 