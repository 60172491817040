import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

export default function CarouselHome() {
    const [index, setIndex] = useState(0);
    const [imgArray, setUrlArray ] = useState(['/img/foto-portada-1.jpeg', '/img/foto-portada-2.jpeg', '/img/foto-portada-3.jpeg', '/img/foto-portada-4.jpeg'])

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel className='carousel-header' activeIndex={index} onSelect={handleSelect} controls={false}>
      <Carousel.Item className='carousel-item'>
        
        <div className='img-container' style={{'backgroundImage': `url(${imgArray[0]})`}}></div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='img-container' style={{'backgroundImage': `url(${imgArray[1]})`}}></div>

      </Carousel.Item>
      <Carousel.Item>
        <div className='img-container' style={{'backgroundImage': `url(${imgArray[2]})`}}></div>
       
      </Carousel.Item>

    </Carousel>
  );
}



