import React, {useEffect} from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faPauseCircle, faCompactDisc } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import { setOnPlay, setOnPause, setProject } from '../../app/playerSlice'

import '../trackItem/trackItem.css'

export default function TrackList({tracks}) {

    
    const dispatch = useDispatch()
    const playerState = useSelector((state)=>{
        return state.player
    })

    let project = playerState.projects.find((item)=>{
        return item._idx===playerState.viewSelected
    }) 

    useEffect(() => {
        console.log(project.albums[playerState.albumSelected].members)
    }, [playerState])


    if(playerState.initialState === true && tracks!==undefined ){
        return(
            <div className='album-container'>
                {
                    project.albums[playerState.albumSelected].members.length>0 ?

                    <div>
                        <h2>Integrantes</h2> 
                        <div class='album-members'>
                            {
                                project.albums[playerState.albumSelected].members.map((item)=>{
                                    let member = item;
                                    return(
                                        <div className='member'>
                                            <h2>{item.name}</h2>
                                            <div className='roll'>

                                            {
                                                                item.roll.map((item) => {

                                                                    if(member.roll[0]===item){
                                                                        return(
                                                                            <h3>{`${item}`}</h3>
                                                                        )    
                                                                    } else {

                                                                        return(
                                                                            <h3>{`, ${item}`}</h3>
                                                                        )
                                                                    }
                                                                })
                                                            }

                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>

                    </div>
                    
                    : ''
                }
                <div className='track-list'>
                    {
                        tracks.map((trackAlbum)=>{
                            const {title, src, _idx} = trackAlbum
                            return(
                                <div className='track-item'>
                                    <FontAwesomeIcon  className={   playerState.project.albums[playerState.albumIndex].tracks[playerState.trackIndex].src === src &&
                                                                    playerState.project.albums[playerState.albumIndex].tracks[playerState.trackIndex]._idx === _idx &&
                                                                    playerState.onPlay === true ? 'card-icon-compact active' : 'card-icon-compact'} icon={faCompactDisc} />
                            
                                    <div className='card-info'>
                                        {title}
                                    </div>
                                    {   
                                        playerState.project.albums[playerState.albumIndex].tracks[playerState.trackIndex].src===src &&
                                        playerState.onPlay === true ?
                                        
                                        <FontAwesomeIcon onClick={
                                            () => {
                                                dispatch(setOnPause({value:true}))
                                                
                                            }
                                        }  className='card-icon-play' icon={faPauseCircle} />  
                                        :
                                        <FontAwesomeIcon onClick={
                                            () => {
                                                dispatch(setOnPlay({value:true}))
                                                dispatch(setProject({projectIndex:playerState.viewSelected, albumIndex: playerState.albumSelected, trackIndex:_idx}))
                                                
                                            }
                                        }  className='card-icon-play' icon={faPlayCircle} />

                                    }
                    
                                </div>
                            )
                        })
                    }
                </div>

            </div>
        )
    }
}