
import './App.css';

import HomeView from './views/Home';
import {AppProvider} from './AppContext';
import { Provider } from 'react-redux'
import store from './app/store'


function App() {


  return (
    <div className="App">
      <Provider store={store}>

        <AppProvider>
          <HomeView/>
        </AppProvider>
        
      </Provider>
    </div>
  );
}

export default App;
