import React, {useState, useContext} from 'react'

import ProyectsView from './views/ProyectsView'
import GalleryView from './views/GalleryView'
import ContactView from './views/ContactView'


import { GalleryContext } from '../galleryContext/GalleryContext'

export default function Article() {

    const [ navState, setNavState, viewer, setViewer] = useContext(GalleryContext)

    return(
        <div className='article'>
            <div className='home-nav'>
                <ul>
                    <li>
                        <button className={navState === 'proyectos' ? 'active' : ''} onClick={ () => {setNavState('proyectos')}}>Proyectos</button>
                    </li>
                
                    <li>
                        <button className={navState === 'galeria' ? 'active' : ''} onClick={() => {setNavState('galeria')}}>Galeria</button>
                    </li>
                    <li>
                        <button className={navState === 'contacto' ? 'active' : ''} onClick={() => {setNavState('contacto')}}>Contacto</button>
                    </li>
                </ul>
            </div>
            <div className='home-article'>
                {
                    navState === 'proyectos' ? <ProyectsView/> : 
                    navState === 'galeria' ? <GalleryView/> :
                    navState === 'contacto' ? <ContactView/> : ''
                }
                
            </div>
        </div>
    )
}