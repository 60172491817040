import React, { useEffect, useContext } from 'react';
import '../gallery/gallery.css';
import Gallery from '../gallery/Gallery';
import Viewer from '../viewer/Viewer';
import { GalleryContext } from '../galleryContext/GalleryContext';
import { useSelector } from 'react-redux';
import './proyectGallery.css'

export default function ProjectGallery() {
    const playerState = useSelector((state) => state.player);
    const [navState, setNavState, viewer, setViewer, galleryView, setGalleryView, galleryList, setGalleryList] = useContext(GalleryContext);

    // Selecciona el proyecto actual
    const project = playerState.projects.find((item) => playerState.viewSelected === item._idx);

    // useEffect para inicializar la galería según el contenido disponible
    useEffect(() => {
        if (project) {
            if (project.media.pictures.length) {
                setGalleryList(project.media.pictures);
                setGalleryView('fotos');
            } else if (project.media.stickers.length) {
                setGalleryList(project.media.stickers);
                setGalleryView('stickers');
            } else if (project.media.videos.length) {
                setGalleryView('videos');
            }
        }
    }, [playerState, project, setGalleryList, setGalleryView]);

    // Cambia el contenido de la galería según la selección en la barra de navegación
    const handleGalleryViewChange = (view) => {
        if (view === 'fotos' && project.media.pictures.length) {
            setGalleryList(project.media.pictures);
        } else if (view === 'stickers' && project.media.stickers.length) {
            setGalleryList(project.media.stickers);
        }
        setGalleryView(view);
    };

    const VideoGallery = () => (
        <div className="video-container">
            {project.media.videos.map((video, index) => (
                <div key={index} className="video-item">
                    <h2>{video.title}</h2>
                    <iframe width="100%" height="100%" src={video.url} title={video.title}></iframe>
                </div>
            ))}
        </div>
    );

    return (
        <div className="gallery-view">
            <div className="gallery-nav">
                <ul>
                    <li>
                        {project?.media.pictures.length ? (
                            <button
                                onClick={() => handleGalleryViewChange('fotos')}
                                className={galleryView === 'fotos' ? 'active' : ''}
                            >
                                Fotos
                            </button>
                        ) : null}
                    </li>
                    <li>
                        {project?.media.stickers.length ? (
                            <button
                                onClick={() => handleGalleryViewChange('stickers')}
                                className={galleryView === 'stickers' ? 'active' : ''}
                            >
                                Stickers
                            </button>
                        ) : null}
                    </li>
                    <li>
                        {project?.media.videos.length ? (
                            <button
                                onClick={() => handleGalleryViewChange('videos')}
                                className={galleryView === 'videos' ? 'active' : ''}
                            >
                                Videos
                            </button>
                        ) : null}
                    </li>
                </ul>
            </div>

            {/* Renderizado condicional según la vista seleccionada */}
            {galleryView === 'fotos' && <Gallery items={galleryList} />}
            {galleryView === 'stickers' && <Gallery items={galleryList} />}
            {galleryView === 'videos' && <VideoGallery />}

            {viewer && <Viewer />}
        </div>
    );
}
