import React, {useState, useContext} from 'react' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { GalleryContext } from '../galleryContext/GalleryContext'

import './viewer.css'

export default function Viewer() {
    
    const  [navState, setNavState, viewer, setViewer, galleryView, setGalleryView, galleryList, setGalleryList, imgSelected, setImgSelected] = useContext(GalleryContext)
    
    const ViewerItem = ({id, img}) => {
        
        return(
            <div class={imgSelected === id ? 'carousel-item active' : 'carousel-item'} style={{'backgroundImage': `url(${img})`}}>

            </div>
        )
    }


    return(
        <div className='viewer-img'>
            <FontAwesomeIcon onClick={() => { setViewer(false) }} className='card-icon' icon={faTimes} />
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    {
                        galleryList.map(item => {
                            return(
                                <ViewerItem id={item.id} img={item.url}/>
                            )
                        })
                    }
           
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}