import React, {useContext, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import './gallery.css'

import { GalleryContext } from '../galleryContext/GalleryContext'


export default function Gallery() {
    
    const [navState, setNavState, viewer, setViewer, galleryView, setGalleryView, galleryList, setGalleryList, imgSelected, setImgSelected] = useContext(GalleryContext)
    

    useEffect(()=>{
        console.log(galleryList)
    }, [])

    const GalleryItem = (props) => {
        let item = props.item

        return(
            <div onClick={()=>{setViewer(true); setImgSelected(item.id)}} className='gallery-img' style={{'backgroundImage': `url(${item.url})`}}>
                    <FontAwesomeIcon className='card-icon' icon={faEye} />
            </div>
        )
    }
    return(
        <div className='gallery'>
            {
                galleryList.map((item)=>{
                    
                    return(
                        <GalleryItem key={item.id} item={item} />
                    )
                })

            }
          
        </div>
    )
}