import React, {useState, useEffect, useContext} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setViewTrackList, setTrack, setInitialState, setOnPlay, setOnPause, setProject, setProjects } from '../../../app/playerSlice'
import ProyectItem from '../../proyects/ProyectItem'
import './proyects.css'
import { projects } from '../../../projects'

    
    const orderData = projects.sort((a, b) => {
        const yearA = a.endYear || a.year;
        const yearB = b.endYear || b.year;
        return yearB - yearA;
    });
    
    
    export default function ProyectsView() {

        const dispatch = useDispatch()

    const playerState = useSelector((state)=>{
        return state.player
    })

    useEffect( () => {
   
        
        
        const projectAlbums = orderData.find((project) => {

            return project.albums.length!==0

        })
     


        if(playerState.initialState===false){
            dispatch(setOnPause({value:true}))

            dispatch(setProjects({array: orderData}))
         
            dispatch(setInitialState({value:true}))
        } else {
            dispatch(setViewTrackList({value:false}))
        }
 
    }, [])

    return(
        <div className='proyects-view'>
            {
                playerState.projects.length > 0 ? playerState.projects.map((item) => {
                    return(<ProyectItem item={item}/>)
                }) : ''
            }
        </div>
    )

}