import React from 'react' 
import { Formik } from 'formik';


import './contact.css'

const Basic = () => (
  <div className='formik'>
      <h2>Enviame un mensaje</h2>
    <Formik
      initialValues={{ email: '', textarea: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <input
            placeholder='Correo electronico'
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && touched.email && errors.email}
          <input className='textarea'
            placeholder='Escribi tu mensaje'
            type="textarea"
            name="textarea"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.textarea}
            
          />
          {errors.textarea && touched.textarea && errors.textarea}
          <button type="submit" disabled={isSubmitting}>
            Enviar
          </button>
        </form>
      )}
    </Formik>
  </div>
);


export default function ContactView() {
    return(
        <div className='contact-view'>
            
            <div className='contact-info'>
                <h2><b>Correo electrónico:</b></h2>
                <h2>angelgiolitti@hotmail.com</h2>
                <h2>angelgiolitti668@gmail.com</h2>
            </div>
            <div className='contact-social'>
                <a href='https://www.instagram.com/loopangeloop/' title='Instagram' target='_blank'>
                    <img src='img/icons/instagram.png' />
                </a>
                <a href='https://www.facebook.com/HandangeL-112863467005285/' title='Facebook' target='_blank'>
                    <img src='img/icons/fb-white.png' />
                </a>
            </div>
         
        </div>
    )
} 